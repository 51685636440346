// Nav Pills
// --------------------------------------------------
.nav-pills {
  > li {
    float: left;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    + li {
      margin-left:0px;
    }
    > a {
      padding: 6px 7px;
      text-transform: uppercase;
      font-size: 13px;
      font-family: 'open_sansB';
      background-color:$gray-lighter;
    }
  }
}