//
// Typography
// --------------------------------------------------


// Headings
// -------------------------

.page-header {
  border-bottom: none;
}